.cordinator-cont{
  display: flex;
 flex-direction: row;
 flex-wrap: wrap; 
}
.coodinator-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  color:white;    
  max-width: 90%;
  margin: 20px auto;
  text-align: center;
  }
  
  .coodinators {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .coodinator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 20px;
    /* border: 2px solid #ccc; */
    cursor: pointer;
    /* position: relative; */
  }
  
  .coodinator.selected {
    filter: blur(2px);
  }
  
  .coodinator img {
    width: 350px;
    height: 450px;
    border-radius: 5%;
    width: 250px;
  box-shadow: 0 4px 8px 0 rgba(180, 19, 19, 0.2), 0 6px 20px 0 rgba(15, 122, 22, 0.19);
  }
  
  .coodinator-details {
    text-align: center;
  }
  
  .coodinator-modal {
    /* position: absolute;
    top: 0;
    left: 0; */
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color:black;
  }
  
  .coodinator-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  
  .coodinator-modal img {
    width: 300px;
    height: 300px;
    /* border-radius: 10%; */
  }
  
  .coodinator-modal button {
    padding-top: 0px;
    font-size: 2rem;
    color:rgb(240, 14, 52);
  }
  /* Small devices (phones, 576px and up) */
@media (min-width: 576px) {
  .coodinator-container {
    padding: 40px;
  }

  .coodinator img {
    width: 100%;
    height: auto;
    max-width: 350px;
    max-height: 450px;
  }
  
  .coodinator-modal img {
    width: 100%;
    height: auto;
    max-width: 300px;
    max-height: 300px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .coodinator-container {
    padding: 50px;
  }
  
  .coodinator img {
    max-width: 450px;
    max-height: 550px;
  }
  
  .coodinator-modal img {
    max-width: 400px;
    max-height: 400px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .coodinator-container {
    padding: 60px;
  }

  .coodinator img {
    max-width: 500px;
    max-height: 600px;
  }
  
  .coodinator-modal img {
    max-width: 500px;
    max-height: 500px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .coodinator-container {
    padding: 70px;
  }
  
  .coodinator img {
    max-width: 450px;
    max-height: 400px;
  }
  
  .coodinator-modal img {
    /* width: auto; */
    max-width: 350px;
    max-height: 350px;
  }
}

  