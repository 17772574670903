.eventspage{
    width: 90%;
    margin: auto; 
    display: flex;
    flex-direction: row;
    row-gap: 5rem;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 2rem 0rem;
    
    
    /* object-fit: cover; */
   

}
.event-div{
     background: linear-gradient(#141e30, #243b55);;
}
/* .about{
    
    background: linear-gradient(#141e30, #243b55);;
} */