.sponsors-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: auto;
    padding: 5rem 0;
    height: 100%;
    cursor: pointer;
    
}
.cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cont h1{
    color:white;
}

.contimg{
    width: 300px;
    height: 300px;
}
.partners{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;
    padding:auto 20px;
}
.eventSponsors{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;
   
}
.eventsponsorsimg{
    display: inline-block;
    margin-top:20px;
    margin-right: 20px;
    width: 500px;
    height: 300px;
    border-radius: 5px;
}