.profile-container {
    display: flex;
    background-color: '#f5f5f5';
     padding: 20px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.profile-card {
    background-color: #fff;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 700px;
    overflow: hidden;
}

.profile-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 10px;
}

.profile-subheading {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 10px;
}

.profile-table {
    margin-top: 30px;
}

.logout-btn {
    background-color: #ff69b4;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 20px;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
    .profile-card {
        padding: 20px;
    }
    
    .profile-heading {
        font-size: 30px;
    }
    
    .profile-subheading {
        font-size: 18px;
    }
    
    .profile-table {
        font-size: 14px;
    }
    
    .logout-btn {
        font-size: 16px;
    }
}
