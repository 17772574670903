.crazybutton {
    /* background: #f5f5f5; */
    display: flex;
    justify-content: center;
    align-items: center;
    
}
 .crazybutton .crazy-btn, .crazybutton .crazy-btn2 {
    min-width: 200px;
    width: auto;
    height: 75px;
    font-size: 30px;
    text-align: center;
    line-height: 75px;
    color: rgba(255,255,255,0.9);
    border-radius: 50px;
    background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5);
    background-size: 600%;
    animation: anime 16s linear infinite;
    cursor: pointer;
}
@media (max-width: 486px) {
   .crazybutton .crazy-btn, .crazybutton .crazy-btn2 {
      padding: 0px 10px;
      line-height: 60px;
      height: 60px;
      min-width: 100px;
      font-size: 20px;
   }
   
 }
   

 .crazybutton .crazy-btn2 {
    position: absolute;
    margin-top: -70px;
    z-index: -1;
    filter: blur(30px);
    opacity: 0.8;
}
 @-moz-keyframes anime {
    0% {
       background-position: 0% 50%;
   }
    50% {
       background-position: 100% 50%;
   }
    100% {
       background-position: 0% 50%;
   }
}
 @-webkit-keyframes anime {
    0% {
       background-position: 0% 50%;
   }
    50% {
       background-position: 100% 50%;
   }
    100% {
       background-position: 0% 50%;
   }
}
 @-o-keyframes anime {
    0% {
       background-position: 0% 50%;
   }
    50% {
       background-position: 100% 50%;
   }
    100% {
       background-position: 0% 50%;
   }
}
 @keyframes anime {
    0% {
       background-position: 0% 50%;
   }
    50% {
       background-position: 100% 50%;
   }
    100% {
       background-position: 0% 50%;
   }
}

.a {
    
   animation: pulse 1s linear infinite;
}

@keyframes pulse {
0% {
 -webkit-transform: scale(1.2);
 transform: scale(1.2);
}
25%{
   -webkit-transform: scale(1.5);
transform: scale(1.5);
}
50% {
-webkit-transform: scale(1.2);
transform: scale(1.2);
}
75%{
   -webkit-transform: scale(1.5);
   transform: scale(1.5);
}
100% {
 -webkit-transform: scale(1);
transform: scale(1);
}
}
 